import React from "react";
import {
  ArrowBottomIconStyled,
  ChildContainer,
  SpaceTaker,
  ZigMenuItem,
  StyledDivider,
} from "./styles";
import { ZigMenuOption, ZigMenuSeparator } from "./types";
import { Box, MenuList } from "@mui/material";
import { Link, useInRouterContext } from "react-router-dom";

export const MenuOption = ({
  option,
  index,
  onClick,
  expandedOption,
  setExpandedOption,
}: {
  option: ZigMenuOption;
  index: number;
  onClick: (cb?: () => void) => (e: React.MouseEvent<HTMLElement>) => void;
  expandedOption?: ZigMenuOption | null;
  setExpandedOption: React.Dispatch<React.SetStateAction<ZigMenuOption | null>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { element, children, label, href, ...optionProps } = option;
  const routerContext = useInRouterContext();

  const renderOption = () => {
    if (element) {
      return (
        <ZigMenuItem
          {...optionProps}
          id={option.id || `dropdown-element-${index}`}
          preserveStyles={option.preserveStyles ?? false}
          onClick={option.onClick || href ? onClick(option.onClick) : undefined}
        >
          {option.element}
        </ZigMenuItem>
      );
    }

    if (href || option.onClick) {
      return (
        <ZigMenuItem
          autoFocus
          {...(href &&
            (routerContext
              ? {
                  component: Link,
                  to: href,
                }
              : { component: "a", href }))}
          {...optionProps}
          onClick={onClick(option.onClick)}
        >
          {label}
        </ZigMenuItem>
      );
    }

    if (option.children) {
      return (
        <ChildContainer active={expandedOption === option}>
          <ZigMenuItem
            id={option.id}
            sx={option.sx}
            onClick={() => option.children?.length && setExpandedOption((v) => (v ? null : option))}
          >
            {label}
            <SpaceTaker />
            {!!option.children?.length && (
              <ArrowBottomIconStyled rotated={expandedOption === option} />
            )}
          </ZigMenuItem>
          {expandedOption === option && (
            <Box display="flex" flexDirection="column">
              {option.children.map(({ label: childLabel, href: childHref, ...o }) => (
                <ZigMenuItem
                  {...o}
                  {...(childHref &&
                    (routerContext
                      ? {
                          component: Link,
                          to: childHref,
                        }
                      : { component: "a", href: childHref }))}
                  onClick={onClick(o.onClick)}
                  key={o.id}
                >
                  {childLabel}
                </ZigMenuItem>
              ))}
            </Box>
          )}
        </ChildContainer>
      );
    }
    return null;
  };

  return (
    <>
      {option.separator && <StyledDivider sx={{ ...option.sx, padding: 0 }} />}
      {renderOption()}
    </>
  );
};

export const MenuContent = ({
  id = "",
  options,
  expandedOption,
  setExpandedOption,
  onClick,
  setOpen,
}: {
  id?: string;
  options: (ZigMenuOption | ZigMenuSeparator)[];
  onClick: (cb?: () => void) => (e: React.MouseEvent<HTMLElement>) => void;
  expandedOption?: ZigMenuOption | null;
  setExpandedOption: React.Dispatch<React.SetStateAction<ZigMenuOption | null>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  return (
    <MenuList id={id && id} aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
      {options.map((option, i) => {
        if (expandedOption && options.indexOf(expandedOption) < i) return null;
        const key =
          (option && "label" in option && typeof option.label === "string" && option.label) ||
          option.id ||
          Math.random().toString();
        return (
          <MenuOption
            option={option}
            index={i}
            key={key}
            onClick={onClick}
            expandedOption={expandedOption}
            setExpandedOption={setExpandedOption}
            setOpen={setOpen}
          />
        );
      })}
    </MenuList>
  );
};
