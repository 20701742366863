import { ZigTheme, ZigThemeOverrides } from "./types";
import dark from "./themes/dark";
import { getMuiTheme } from "./muiTheme";
import { Theme } from "@mui/system";

export const zignalyBaseThemeConfig = dark;

export const getZignalyThemeExport = (
  themeName?: ZigTheme["mode"],
  overrides?: ZigThemeOverrides | ZigThemeOverrides[],
): Theme => {
  const theme = dark;
  return getMuiTheme(theme, overrides ? (Array.isArray(overrides) ? overrides : [overrides]) : []);
};

export type { ZigTheme as ThemeType, ZigThemeOverrides as ThemeOverridesType } from "./types";

export { GlobalAppStyle } from "./GlobalAppStyle";
