import { useToast as getToastUi } from '@zignaly-open/ui';
import { useTranslation } from 'react-i18next';
import { BackendError } from '../errors';

const backendErrorText = (t: (key: string) => string, error: BackendError) => {
  const { code, msg } = error?.data?.error || {};
  const { detail: alternativeMsg } = (error?.data || {}) as { detail?: string };
  const translationKey = 'error:error.' + code;
  if (code && t(translationKey) !== translationKey.replace(/^error:/, '')) {
    return t(translationKey);
  } else {
    if (msg || alternativeMsg) {
      return msg || alternativeMsg;
    } else {
      return t('error:something-went-wrong');
    }
  }
};

const ignoreError = (error: BackendError) => {
  const { code } = error?.data?.error || {};
  return [1010, 1091, 1109, 1122].includes(code);
};

export const backendError = (
  t: (key: string) => string,
  error: BackendError,
) => {
  if (ignoreError(error)) return null;

  const text = backendErrorText(t, error);
  return getToastUi().error(text, { toastId: text?.toLocaleLowerCase() });
};

export function useToast(): ReturnType<typeof getToastUi> & {
  backendError: (error?: BackendError, ignoreDuplicate?: boolean) => void;
} {
  const { t } = useTranslation<'error'>('error');
  return {
    ...getToastUi(),
    backendError: (error: BackendError) => backendError(t, error),
  };
}
