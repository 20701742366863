import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'util/title';
import { PageContainer } from '@zignaly-open/ui';
import SignupForm from './components/SignupForm';
import { useMaybeSaveMissedDestinationUrl } from '../../util/navigation';
import { ROUTE_LOGIN } from '../../routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { WrapperPlain } from './components/SignupForm/styles';

const SignupPlain: React.FC = () => {
  const { t } = useTranslation(['pages', 'sign-up']);
  useMaybeSaveMissedDestinationUrl();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  useTitle(t('pages:signup'));

  return (
    <PageContainer
      style={{
        marginTop: '32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <WrapperPlain>
        <SignupForm
          plain
          onClickLogin={() => navigate(ROUTE_LOGIN, { state: locationState })}
        />
      </WrapperPlain>
    </PageContainer>
  );
};

export default SignupPlain;
