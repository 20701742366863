import { ChartColors } from "../types";
import { useMemo } from "react";
import * as d3Scale from "victory-vendor/d3-scale";
import { AxisFormat } from "../types";

const THRESHOLD = 14;

export function useChartColor(): ChartColors {
  return {
    red: "#CC3993",
    green: "#039179",
    greenGradient: ["rgba(18, 33, 59, 0.52)", "rgba(33, 81, 78, 0.69)"],
    greenMiniGradient: ["rgba(17, 27, 47, 0)", "rgba(22, 41, 67, 0.5)", "rgba(39, 110, 107, 1)"],
    redGradient: ["rgba(31, 18, 59, 0.52)", "rgba(74, 33, 81, 0.69)"],
    redMiniGradient: ["rgba(18, 20, 39, 0)", "rgba(21, 21, 57, 0.5)", "rgba(86, 36, 108, 1)"],
  };
}

export const useTicks = (
  data: AxisFormat[],
  yDomain: [number, number],
  tickCount: number,
  onlyIntegerTicks = false,
  showCurrentTick = false,
  height = 0,
) => {
  return useMemo(() => {
    const lastDataY = data[data.length - 1]?.y;

    const ticks = d3Scale
      .scaleLinear()
      .domain(yDomain)
      .ticks(tickCount)
      .filter((v) => !onlyIntegerTicks || Number.isInteger(v));

    if (!showCurrentTick) {
      return ticks;
    }

    const scale = d3Scale.scaleLinear().domain(yDomain).range([0, height]);

    const lastDataPointPositionScaled = scale(lastDataY);

    const overlapsExistingTick = ticks.find((existingTickPosition) => {
      const existingTickPositionScaled = scale(existingTickPosition);
      return Math.abs(existingTickPositionScaled - lastDataPointPositionScaled) < THRESHOLD;
    });

    const adjustedTicks = overlapsExistingTick
      ? ticks.filter((tick) => tick !== overlapsExistingTick)
      : ticks;

    return [...adjustedTicks, lastDataY];
  }, [data, yDomain, tickCount, onlyIntegerTicks, height]);
};
