import { isCapacitor } from '@ui/utils/capacitor';
import { useCurrentUser } from '../apis/user/use';
import { useEffect } from 'react';
import { Intercom } from '@capacitor-community/intercom';

// import { PushNotifications } from '@capacitor/push-notifications';
// PushNotifications.register();

export function useInitIntercom() {
  const user = useCurrentUser();
  useEffect(() => {
    if (user) {
      const { userId, email, intercomHash } = user;

      if (isCapacitor && userId && intercomHash) {
        Intercom.registerIdentifiedUser({
          userId,
          email,
        });
        Intercom.setUserHash({ hmac: intercomHash });
      } else if (window.intercomSettings) {
        window.intercomSettings.user_hash = intercomHash;
      }
    }
  }, [user]);
}
