import React, { useEffect, useMemo, useState } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import ZModal from '../../../../components/ZModal';
import { QueryReturnTypeBasic } from 'util/queryReturnType';
import EmailVerifyForm from '../EmailVerifyForm';
import { useCurrentUser, useResendCode } from '../../../../apis/user/use';
import { ZigTypography } from '@zignaly-open/ui';
import { useToast } from '../../../../util/hooks/useToast';
import { Container, Title } from '../AuthVerifyModal/styles';
import TwoFAForm from '../TwoFAForm';

function AuthorizationModal({
  close,
  action,
  sendCode: sendCodeCustom,
  codeReason,
  status,
  title,
  description,
  prefixId = 'auth-verify-modal',
  ...props
}: {
  close: () => void;
  action: (code: string, code2FA?: string) => void;
  sendCode?: () => Promise<void>;
  codeReason: string;
  title?: string;
  description?: string;
  status: QueryReturnTypeBasic<void>;
  prefixId?: string;
} & DialogProps): React.ReactElement {
  const { ask2FA } = useCurrentUser();
  const [sendCode] = useResendCode();
  const sendCodeFn = sendCodeCustom || sendCode;
  const { t } = useTranslation(['auth', 'error']);
  const [code2FA, setCode2FA] = useState<string | null>(null);
  const [codeEmail, setCodeEmail] = useState<string | null>(null);

  // Ignore double mounts in strict mode
  let ignore = false;
  useEffect(() => {
    if (!ignore) {
      sendCodeFn(codeReason);
    }

    return () => {
      ignore = true;
    };
  }, []);

  const toast = useToast();
  const performResend = () => {
    sendCodeFn(codeReason).then(() => toast.success(t('auth:resend-code')));
  };

  useEffect(() => {
    if (status.isSuccess) {
      close();
    }
  }, [status.isSuccess]);

  useEffect(() => {
    if (codeEmail && (!ask2FA || code2FA)) {
      action(codeEmail, code2FA);
    }
  }, [codeEmail, code2FA]);

  const errorCode = (status.error as { data?: { error: { code: number } } })
    ?.data?.error.code;

  const error = useMemo(() => {
    return errorCode === 13
      ? t('error:error.login-session-expired')
      : [48, 1086].includes(errorCode)
        ? t(`error:error.${errorCode}`)
        : null;
  }, [t, status]);

  const error2FA = useMemo(() => {
    return errorCode === 37 ? t(`error:error.${errorCode}`) : null;
  }, [t, status]);

  return (
    <ZModal
      {...props}
      disableBackdropClose
      allowUnauth
      wide
      mobileFullScreen
      close={close}
      title={title}
      titleAlign='center'
    >
      {description && (
        <Title data-testid={`${prefixId}__title`}>
          <ZigTypography
            whiteSpace='pre-line'
            id={`${prefixId}__description`}
            textAlign={'center'}
          >
            {description}
          </ZigTypography>
        </Title>
      )}
      <Container isSeveralCodes={ask2FA}>
        <EmailVerifyForm
          clearOnError
          onReSendCode={performResend}
          onSubmit={setCodeEmail}
          onChange={(c) => !c && setCodeEmail(null)}
          isLoading={status.isLoading}
          error={error}
          prefixId={`${prefixId}-email`}
          // auto focus initially
          // force setting it to false if request loading to be able to re-focus if error
          autoFocus={!status.isLoading || !error}
        />
        {ask2FA && (
          <TwoFAForm
            clearOnError
            onSubmit={setCode2FA}
            onChange={(c) => !c && setCode2FA(null)}
            isLoading={status.isLoading}
            error={error2FA}
            prefixId={`${prefixId}-2fa`}
            // auto focus after email code is filled
            // force setting it to false if request loading to be able to re-focus if error
            autoFocus={!!codeEmail && !status.isLoading && !error}
          />
        )}
      </Container>
    </ZModal>
  );
}

export default AuthorizationModal;
