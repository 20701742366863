import React, { useCallback, useMemo, useRef } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { UserAccessLevel as Level } from '../../apis/user/types';
import {
  useIsAuthenticated,
  useLogout,
  useUserAccessLevel,
} from '../../apis/user/use';
import { isFeatureOn } from '../../whitelabel';
import { Features } from '../../whitelabel/type';
import { useTranslation } from 'react-i18next';
import { useZModal } from '../../components/ZModal/use';
import { ROUTE_KYC, ROUTE_SUBSCRIPTIONS } from '../../routes';
import { AlertModalProps } from '@zignaly-open/ui/components/display/ZModal/modals/AlertModal';
import { useZAlert } from '@zignaly-open/ui';

type LevelMapping =
  | false
  | {
      props: AlertModalProps;
    };

const usePerformLevelCheck = (
  levelThreshold: Level,
  onClose?: () => void,
): ((onlyCheck?: boolean) => boolean) => {
  const isAuthenticated = useIsAuthenticated();
  const accessLevel = useUserAccessLevel();
  const { t } = useTranslation(['error']);
  const { destroyModal } = useZModal();
  const showAlert = useZAlert();
  const logout = useLogout();
  const navigate = useNavigate();
  const kycEnabled = isFeatureOn(Features.Kyc);
  const subscriptionsEnabled = isFeatureOn(Features.Subscriptions);
  const modalId = useRef('');

  const closeAndNavigate = useCallback(
    (path: string) => {
      // Close modal manually
      destroyModal(modalId.current);
      // If we have a modal open, close it (in case disableAutoDestroy is on)
      onClose?.();
      // Navigate to the path
      navigate(path);
    },
    [navigate, destroyModal, onClose],
  );

  const errorLevelMapping = useMemo<Partial<Record<Level, LevelMapping>>>(
    () => ({
      [Level.Banned]: {
        props: {
          title: t('access.banned.title'),
          okLabel: t('access.banned.action'),
          description: t('access.banned.description'),
          okAction: () => logout(),
        },
      },
      [Level.NotVerified]: kycEnabled && {
        props: {
          title: t('access.mail-not-verified.title'),
          okLabel: t('access.mail-not-verified.action'),
          description: t('access.mail-not-verified.description'),
          okAction: () => logout(),
        },
      },
      [Level.KycPending]: kycEnabled && {
        props: {
          title: t('access.kyc-pending.title'),
          okButtonProps: {
            id: 'kyc__kyc-wall-link',
          },
          okLabel: t('access.kyc-pending.action'),
          description: t('access.kyc-pending.description'),
          okAction: () => closeAndNavigate(generatePath(ROUTE_KYC)),
        },
      },
      [Level.NoSubscription]: subscriptionsEnabled && {
        props: {
          title: t('access.no-subscription.title'),
          description: t('access.no-subscription.description'),
          okLabel: t('access.no-subscription.redeem'),
          okAction: () => closeAndNavigate(generatePath(ROUTE_SUBSCRIPTIONS)),
        },
      },
      [Level.Frozen]: {
        props: {
          title: t('access.frozen.title'),
          description: t('access.frozen.description'),
          okLabel: t('access.frozen.action'),
        },
      },
      [Level.KycExpired]: kycEnabled && {
        props: {
          title: t('access.kyc-expired.title'),
          description: t('access.kyc-expired.description'),
          okLabel: t('access.kyc-expired.action'),
          okAction: () => closeAndNavigate(generatePath(ROUTE_KYC)),
        },
      },
      [Level.SubscriptionExpired]: subscriptionsEnabled && {
        props: {
          title: t('access.expired-subscription.title'),
          description: t('access.expired-subscription.description'),
          okLabel: t('access.expired-subscription.redeem'),
          okAction: () => closeAndNavigate(generatePath(ROUTE_SUBSCRIPTIONS)),
        },
      },
    }),
    [t, logout],
  );

  return (onlyCheck = false) => {
    // filter out string keys of the enum
    for (const l of Object.keys(Level).filter((k) => !Number.isNaN(+k))) {
      if (!isAuthenticated || !accessLevel) {
        // Do nothing here
      } else if (levelThreshold <= +l) {
        // Do nothing, means we do not need so high of a level
      } else if (accessLevel <= +l && errorLevelMapping[l]) {
        if (!onlyCheck) {
          const modal = showAlert(errorLevelMapping[l].props);
          modalId.current = modal.id;
        }
        return false;
      }
    }
    return true;
  };
};

const createUseAccessCheck = (level: Level) => (onClose?: () => void) =>
  usePerformLevelCheck(level, onClose);

export const useCanLogIn = createUseAccessCheck(Level.KycPending);
export const useCanInsertCoupon = createUseAccessCheck(Level.NoSubscription);
export const useCanDeposit = createUseAccessCheck(Level.Normal);
export const useCanInvestIn = createUseAccessCheck(Level.Normal);
export const useCanCreateService = createUseAccessCheck(Level.Normal);
export const useCanInvestOut = createUseAccessCheck(Level.KycPending);
export const useCanWithdraw = createUseAccessCheck(Level.KycPending);
export const useCanSwapCoins = createUseAccessCheck(Level.Normal);

export const withModalRequiresAuthentication = <
  T extends { close: () => void },
>(
  Component: React.FC<T>,
): React.FC<T> => {
  return (props) => {
    const isAuthenticated = useIsAuthenticated();
    if (isAuthenticated) {
      return <Component {...props} />;
    } else {
      props.close();
      return null;
    }
  };
};
