import React, { ComponentProps } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorStub from './components/ErorStub';
import * as Sentry from '@sentry/react';
import { useUpdateEffect } from 'react-use';

const WrappedFallback = ({
  resetError,
  children,
}: {
  resetError: () => void;
  children: JSX.Element;
}) => {
  const location = useLocation();
  useUpdateEffect(() => {
    resetError();
  }, [location.pathname]);

  return children;
};

const ZigErrorBoundary = ({
  children,
  ...props
}: ComponentProps<typeof Sentry.ErrorBoundary>) => {
  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => (
        <WrappedFallback resetError={errorData.resetError}>
          {typeof props.fallback === 'function'
            ? props.fallback(errorData)
            : props.fallback || <ErrorStub />}
        </WrappedFallback>
      )}
      beforeCapture={(scope) => {
        scope.setLevel('fatal');
      }}
      {...props}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ZigErrorBoundary;
