import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'util/title';
import { ZignalyLogotype, ZigStub } from '@ui/index';
import { Box, Container } from '@mui/material';
import { Build } from '@mui/icons-material';
import { isZignaly, whitelabel } from 'whitelabel';

const Maintenance: React.FC = () => {
  const { t } = useTranslation('error');
  useTitle(t('maintenance.title'));
  const logo = !isZignaly ? (
    <img src={whitelabel.logo} id='maintenance__logo' height='32' />
  ) : (
    <ZignalyLogotype width={'134px'} height={'32px'} id={'maintenance__logo'} />
  );

  useEffect(() => {
    // Redirect to home page after 5 minutes
    const timer = setTimeout(
      () => {
        window.location.href = '/';
      },
      5 * 60 * 1000,
    );

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      <Box mt='14px'>
        <a href='/'>{logo}</a>

        <ZigStub
          title={
            <Box display='flex' alignItems='center' gap={1}>
              <Build sx={{ fontSize: 28 }} />
              {t('maintenance.title')}
            </Box>
          }
          description={t('maintenance.description')}
        />
      </Box>
    </Container>
  );
};

export default Maintenance;
