import ls from '@livesession/sdk';
import { UserData } from 'apis/user/types';
import { isFeatureOn } from '../whitelabel';
import { Features } from '@zignaly-open/ps2-definitions';

const isEnabled =
  isFeatureOn(Features.EnableTracking) &&
  import.meta.env.VITE_LIVE_SESSION_TRACK_ID;

export const startLiveSession = (user: UserData) => {
  if (isEnabled) {
    ls.init(import.meta.env.VITE_LIVE_SESSION_TRACK_ID);
    ls.identify({
      name: user.firstName,
      email: user.email,
      params: {
        userId: user.userId,
        exchangeConnected: user.binanceConnected,
        providerEnabled: user.providerEnable,
        openCount: user.buysCount,
        closeCount: user.sellsCount,
        hasActivated: user.hasActivated,
      },
    });
    ls.newPageView();
  }
};

export const endLiveSession = () => {
  if (isFeatureOn(Features.EnableTracking)) {
    ls.init(import.meta.env.VITE_LIVE_SESSION_TRACK_ID);
    ls.invalidateSession();
  }
};
