import { Intercom } from '@capacitor-community/intercom';
import { isCapacitor } from '@ui/utils/capacitor';

const crutchToOpenCapacitorOnUIOnMobile = () => {
  const intercomLauncher = document.querySelector(
    '.intercom-launcher',
  ) as HTMLElement;
  const iframeLauncher = document.querySelector(
    '.intercom-launcher-frame',
  ) as HTMLIFrameElement;

  if (intercomLauncher) {
    intercomLauncher.click();
  } else {
    const iframeContent = iframeLauncher?.contentDocument?.querySelector(
      '.intercom-launcher',
    ) as HTMLElement;
    iframeContent?.click();
  }
};

export const openIntercom = () => {
  if (isCapacitor) {
    Intercom.displayMessenger().catch(console.error);
  } else {
    crutchToOpenCapacitorOnUIOnMobile();
  }
};
