import { Alert } from '@mui/material';
import { useInvestmentsQuery } from 'apis/investment/api';
import { useKycStatusesQuery, useLazyUserQuery } from 'apis/user/api';
import { setUser } from 'apis/user/store';
import { KycStatus, UserAccessLevel } from 'apis/user/types';
import { useActiveExchange, useCurrentUser } from 'apis/user/use';
import AnchorLink from 'components/AnchorLink';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAsync } from 'react-use';
import { ROUTE_2FA, ROUTE_KYC, ROUTE_SUBSCRIPTIONS } from 'routes';
import { isFeatureOn } from 'whitelabel';
import { Features } from 'whitelabel/type';
import { SUBSCRIPTIONS_CHECK_INTERVAL } from '../Checkers/constants';

const RequiredActionsBanner = () => {
  const user = useCurrentUser();
  const exchange = useActiveExchange();
  const [loadUser] = useLazyUserQuery();
  const skipKyc =
    !exchange?.internalId || !isFeatureOn(Features.Kyc) || !exchange?.activated;
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  useAsync(async () => {
    // Refresh user data in case of accessLevel updated from outside.
    // And probably good to have up to date user data.
    const userData = await loadUser().unwrap();
    dispatch(setUser(userData));
  }, []);

  const { isLoading: investmentsLoading, data: investments } =
    useInvestmentsQuery(exchange?.internalId);
  const { data: statusesRes, isLoading: kycLoading } = useKycStatusesQuery(
    undefined,
    {
      skip: skipKyc,
    },
  );

  const noKyc = statusesRes?.statuses.some(
    (s) =>
      s.category === 'KYC' &&
      s.level === 1 &&
      [
        KycStatus.NOT_STARTED,
        KycStatus.INIT,
        KycStatus.REJECTED_RETRY,
      ].includes(s.status),
  );

  const needsKyc =
    isFeatureOn(Features.Kyc) &&
    user.exchanges?.length === 1 &&
    (!exchange.activated || noKyc);

  const needs2FA = investments?.length && !user['2FAEnable'];

  const expiredSubscription =
    isFeatureOn(Features.Subscriptions) &&
    user.accessLevel === UserAccessLevel.SubscriptionExpired;

  // Periodic refresh of user data to handle subscriptions status
  useEffect(() => {
    if (!isFeatureOn(Features.Subscriptions)) return;

    const intervalId = setInterval(async () => {
      const userData = await loadUser().unwrap();
      dispatch(setUser(userData));
    }, SUBSCRIPTIONS_CHECK_INTERVAL);
    return () => clearInterval(intervalId);
  }, [user.subscriptionFinishesAt]);

  if (
    kycLoading ||
    investmentsLoading ||
    (!needsKyc && !needs2FA && !expiredSubscription)
  )
    return null;

  return (
    <Alert
      severity={expiredSubscription ? 'error' : 'warning'}
      id='action-banner'
    >
      {needsKyc ? (
        <Trans t={t} i18nKey={'pass-kyc'}>
          <AnchorLink to={ROUTE_KYC} target={'_self'} />
        </Trans>
      ) : expiredSubscription ? (
        <Trans t={t} i18nKey={'subscription-expired'}>
          <AnchorLink to={ROUTE_SUBSCRIPTIONS} />
        </Trans>
      ) : (
        <Trans t={t} i18nKey={'secure-account'}>
          <AnchorLink to={ROUTE_2FA} target={'_self'} />
        </Trans>
      )}
    </Alert>
  );
};

export default RequiredActionsBanner;
