import { ROUTE_LOGIN, ROUTE_SIGNUP } from '../../routes';
import { useIsAuthenticated } from '../../apis/user/use';
import { junkyard } from '../junkyard';
import { isFeatureOn } from '../../whitelabel';
import { Features } from '../../whitelabel/type';
import { useZModal } from '../../components/ZModal/use';
import AuthModal from '../../views/Auth/modals/AuthModal';

export const getNotLoggedInNavigationRoute = (): string => {
  const newUser = !junkyard.get('hasLoggedIn');
  return newUser && !isFeatureOn(Features.LoginOnlyAccess)
    ? ROUTE_SIGNUP
    : ROUTE_LOGIN;
};

export default function useMaybeShowAuthModalNotLoggedIn(): () => void {
  const isAuthenticated = useIsAuthenticated();
  const { showModal } = useZModal();
  const newUser = !junkyard.get('hasLoggedIn');
  return () =>
    !isAuthenticated &&
    showModal(AuthModal, {
      authType:
        newUser && !isFeatureOn(Features.LoginOnlyAccess) ? 'signup' : 'login',
    });
}
