export const sortByValue = (number1: string | number, number2: string | number) => {
  return +number1 - +number2;
};

export const trimZeros = (v: string | number) => {
  return v?.toString()?.replace(/(\.\d*?[1-9]+)0+$|\.0+$/g, "$1");
};

export const numberOfDecimals = (v: string | number) => {
  return (v?.toString()?.split(".")[1] ?? "").length;
};

export const formatCompactNumber = (value: number | string, precision = 8) =>
  new Intl.NumberFormat("en-US", {
    maximumFractionDigits: precision,
    notation: "compact",
    compactDisplay: "short",
  }).format(+value);

/**
 * Returns a number with a fixed number of decimals, not showing trailing zeros.
 */
export const prettyFloat = (value: number | string, decimals = 2): number => {
  const num = typeof value === "string" ? parseFloat(value) : value;
  return parseFloat(num.toFixed(decimals));
};

/**
 * Returns a number with a fixed number of decimals rounding floor, not showing trailing zeros.
 */
export const prettyFloatToFloor = (value: number | string, decimals = 2): number => {
  const num = typeof value === "string" ? parseFloat(value) : value;
  const [integerPart, decimalPart] = num.toString().split(".");

  if (decimalPart) {
    return parseFloat(`${integerPart}.${decimalPart.slice(0, decimals)}`);
  }
  return num;
};
