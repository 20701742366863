import React from 'react';
import LoginForm from './components/LoginForm';
import { PageContainer, ZigTypography } from '@zignaly-open/ui';
import { useTitle } from 'util/title';
import { useTranslation } from 'react-i18next';
import { useMaybeSaveMissedDestinationUrl } from '../../util/navigation';
import { ROUTE_SIGNUP } from '../../routes';
import { useLocation, useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  useMaybeSaveMissedDestinationUrl();
  const { t } = useTranslation(['pages', 'auth']);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  useTitle(t('login'));

  return (
    <PageContainer
      style={{
        marginTop: '32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 36px',
      }}
    >
      <ZigTypography variant={'h1'} align={'center'} pt={4}>
        {t('auth:log-in-title')}
      </ZigTypography>
      <LoginForm
        onClickSignUp={() => navigate(ROUTE_SIGNUP, { state: locationState })}
      />
    </PageContainer>
  );
};

export default Login;
