import { useEffect, useState } from "react";
import ReactCodeInput from "react-verification-input";
import { InputCodeProps } from "./types";
import { Layout } from "./styles";
import { ErrorMessage } from "../../display/ZigAlertMessage";
import { Box } from "@mui/material";
import { Loader } from "@ui/components/display/Loader";

function ZigInputCode({
  fields,
  loading,
  onComplete,
  onChange,
  autoFocus = false,
  clearOnError = false,
  error = null,
  prefixId,
  autoComplete = "one-time-code",
}: InputCodeProps) {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (error && clearOnError) {
      setValue("");
      onChange?.("");
    }
  }, [error, clearOnError]);

  return (
    <Layout error={error || undefined} loading={loading}>
      <Box>
        <Box id={prefixId && `${prefixId}__code-input`} position={"relative"}>
          <ReactCodeInput
            onChange={(v) => {
              setValue(v);
              onChange?.(v);
            }}
            value={value}
            length={fields}
            validChars="0-9"
            autoFocus={autoFocus}
            onComplete={onComplete}
            placeholder=""
            inputProps={{ autoComplete: autoComplete }}
          />
          {loading && (
            <Box
              position={"absolute"}
              top={0}
              left={0}
              right={0}
              bottom={0}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Loader width={18} height={18} />
            </Box>
          )}
        </Box>
        {error && <ErrorMessage text={error} id={prefixId && `${prefixId}__error-message`} />}
      </Box>
    </Layout>
  );
}

export default ZigInputCode;
