import i18n, { supportedLanguages } from 'util/i18n/i18next';
import { useTheme } from '@mui/material';
import React from 'react';
import { LocalizationLanguages } from '@zignaly-open/ps2-definitions';
import { GlobeLanguagesStyled } from '../ExtraNavigationDropdown/styles';
import { ZigButton, ZigMenu } from '@zignaly-open/ui';
import { useChangeLocale } from 'apis/user/use';

const LanguagesSelector = () => {
  const theme = useTheme();
  const languageMap = supportedLanguages
    ? supportedLanguages.map((x) => LocalizationLanguages[x])
    : Object.values(LocalizationLanguages);
  const changeLocale = useChangeLocale();

  const languageOptions = languageMap.map((language, index) => ({
    id: `menu-dropdown-languages__${index.toString()}`,
    active: i18n.language === language.locale,
    label: language.label,
    onClick: () => changeLocale(language.locale),
  }));

  if (languageMap.length <= 1) return null;

  return (
    <ZigMenu
      menuSx={{
        border: `1px solid ${theme.palette.neutral700}`,
        boxShadow: '0px 7px 14px -7px rgba(25, 25, 39, 0.36)',
      }}
      component={() => (
        <ZigButton
          id='menu-dropdown__language-switcher'
          variant='outlined'
          sx={{ border: 'none !important' }}
          startIcon={<GlobeLanguagesStyled color={theme.palette.neutral300} />}
        >
          {LocalizationLanguages[i18n.language?.split('_')[0]]?.label}
        </ZigButton>
      )}
      options={languageOptions}
    />
  );
};

export default LanguagesSelector;
