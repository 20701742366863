import { zignalyUiI18nAllTranslations } from '@zignaly-open/ui/i18n';
import enCommon from '../../locales/en/common.json';
import enAuth from '../../locales/en/auth.json';
import enError from '../../locales/en/error.json';
import enPages from '../../locales/en/pages.json';
import enAction from '../../locales/en/action.json';
import enKyc from '../../locales/en/kyc.json';
import enSignUp from '../../locales/en/sign-up.json';

// Eager load core translations
const coreTranslations = {
  en: {
    common: enCommon,
    auth: enAuth,
    error: enError,
    pages: enPages,
    action: enAction,
    kyc: enKyc,
    'sign-up': enSignUp,
  },
};

export default {
  request: async (options, url, payload, callback) => {
    const [lng, ns] = url.split('/');

    const returnFailure = (consoleOutput: unknown) => {
      console.error(consoleOutput);
      callback({}, { status: 404 });
    };

    const returnSuccess = (data: Record<string, unknown>) => {
      callback(null, {
        data: JSON.stringify(data),
        status: 200,
      });
    };

    // Check if we have it in core translations
    if (coreTranslations[lng]?.[ns]) {
      return returnSuccess(coreTranslations[lng][ns]);
    }

    if (!/^[a-z\d-]+$/.test(ns) || !/^[a-z\d-]+$/.test(lng)) {
      return returnFailure('Why do you have those chars in the ns/language?');
    }

    if (ns === 'zignaly-ui') {
      if (zignalyUiI18nAllTranslations?.[lng]) {
        return returnSuccess(zignalyUiI18nAllTranslations?.[lng]);
      } else {
        return returnFailure(`No translation for ${lng} in zignaly-ui`);
      }
    }

    if (ns === 'links-overrides') {
      // for the record, I told them it was a bad idea
      // and the links should eb a part of the wl config rather than translations
      return returnSuccess({});
    }

    try {
      const { default: data } = await import(`../../locales/${lng}/${ns}.json`);
      return returnSuccess(data);
    } catch (e) {
      return returnFailure(e);
    }
  },
  loadPath: '{{lng}}/{{ns}}',
};
