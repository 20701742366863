import { useCurrentUser } from '../../../apis/user/use';
import { tzTrackClick } from '../../../util/analytics';

export default function useTrackEvent(): (ctaId: string) => void {
  const { userId } = useCurrentUser();
  return (ctaId) =>
    tzTrackClick({
      userId,
      ctaId,
    });
}
