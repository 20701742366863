import baseApiPs2 from '../baseApiPs2';
import { logout } from './store';
import { endLiveSession } from '../../util/liveSession';
import { trackEndSession } from '../../util/analytics';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

let lastLogoutTime = 0;

export const clearUserSession = (
  dispatch: Dispatch<AnyAction>,
  force = false,
) => {
  // Clear user session (only once to avoid multiple queries triggering refetch)
  const currentTime = Date.now();
  if (currentTime - lastLogoutTime >= 30000 || force) {
    dispatch(logout());
    dispatch(baseApiPs2.util.resetApiState());
    endLiveSession();
    trackEndSession();
    lastLogoutTime = currentTime;
  }
};
