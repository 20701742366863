import { ShowFnOutput, useModal, UseModalOptions } from "mui-modal-provider";
import { useCallback } from "react";
import AlertModal, { AlertModalProps } from "./modals/AlertModal";
import ConfirmModal, { ConfirmModalProps } from "./modals/ConfirmModal";
import TypeTextConfirmModal, { TypeTextConfirmModalProps } from "./modals/TypeTextConfirmModal";
import { UseZModalOptions } from "./types";
import PromptModal, { PromptModalProps } from "./modals/PromptModal";

export function useZModal(options?: UseModalOptions) {
  const { showModal, ...etc } = useModal(options);
  const ourShowModal = useCallback(
    (Component: any, props?: Record<string, unknown>, opts?: Record<string, unknown>) => {
      const modal: ShowFnOutput<void> = showModal(
        Component,
        {
          ...(props || {}),
          close: () => modal.destroy(),
        },
        opts,
      );
      return modal;
    },
    [showModal],
  );

  return {
    ...etc,
    showModal: ourShowModal,
    originalShowModal: showModal,
  };
}

export function useZAlert(
  modalProps?: UseZModalOptions,
): (props: AlertModalProps) => ShowFnOutput<void> {
  const { showModal } = useZModal(modalProps);
  return (props) => showModal(AlertModal, props);
}

export function useZConfirm(): (props: ConfirmModalProps) => ShowFnOutput<void> {
  const { showModal } = useZModal();
  return (props) => showModal(ConfirmModal, props);
}

export function useZTypeWordConfirm(
  modalProps?: UseZModalOptions,
): (props: TypeTextConfirmModalProps) => ShowFnOutput<void> {
  const { showModal } = useZModal(modalProps);
  return (props) => showModal(TypeTextConfirmModal, props);
}

export function useZPrompt(): (props: PromptModalProps) => ShowFnOutput<void> {
  const { showModal } = useZModal();
  return (props) => showModal(PromptModal, props);
}
