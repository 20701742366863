import { styled } from "@mui/material";

export const Layout = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Icon = styled("div")`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;
