import React from 'react';
import { useTranslation } from 'react-i18next';
import { ZigStub } from '@zignaly-open/ui';

const NotFound: React.FC = () => {
  const { t } = useTranslation('common');
  return <ZigStub title={t('404.title')} description={t('404.description')} />;
};

export default NotFound;
