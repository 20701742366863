import React, { useEffect } from 'react';
import { useIsAuthenticated } from '../../apis/user/use';
import { Navigate, Outlet } from 'react-router-dom';
import { useCanLogIn } from './util';
import { getNotLoggedInNavigationRoute } from '../hooks/useMaybeShowAuthModalNotLoggedIn';

const AuthenticatedWall: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const checkCanLogin = useCanLogIn();

  useEffect(() => {
    isAuthenticated && checkCanLogin();
  }, [isAuthenticated]);

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={getNotLoggedInNavigationRoute()} />
  );
};

export default AuthenticatedWall;
