import {
  osVersion,
  isIOS,
  isSafari,
  isMobileSafari,
} from 'react-device-detect';
import { isCapacitorIos } from '@zignaly-open/ui';

const [major, minor] = osVersion?.split('.') || [];

export const isOldBadSafari =
  isIOS &&
  (isSafari || isCapacitorIos || isMobileSafari) &&
  (+major < 15 || (+major === 15 && +minor <= 7));
