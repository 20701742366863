import { ZigTheme } from "../types";

const dark: ZigTheme = {
  mode: "dark",
  fontFamily: ["Avenir Next", "Roboto", "Helvetica", "Arial", "sans-serif"],
  // having this key is important
  // without it overriding would not work
  fontFamilyH1H6: undefined,
  palette: {
    neutral900: "#06061A",
    neutral800: "#12152c",
    neutral750: "#171b30",
    neutral700: "#252339",
    neutral600: "#35334A",
    neutral500: "#4A4958",
    neutral400: "#706F82",
    neutral300: "#89899A",
    neutral200: "#A9A9BA",
    neutral175: "#889AD0",
    neutral150: "#BAC1E4",
    neutral100: "#C1C1C8",
    neutral000: "#E1E9F0",
    secondary: "#515AAC",
    highlighted: "#7682F7",
    red: "#CC3993",
    criticalRed: "#CC394E",
    green: "#26c496",
    links: "#26C4C1",
    yellow: "#AE9C5B",
    contrasting: "#F3F4F6",
    lightGrey: "#9ca3af",
    paleBlue: "#999fe1",
  },
  backgrounds: {
    body: `url(https://imagedelivery.net/qNg0fDlw9b2DximxcnB4cA/4048a0ac-9c9d-4298-4146-fb7b1524d900/public) #070819`,
    header: "linear-gradient(269.14deg, #080810 0%, #11122b 100%)",
    neutralBg: "#101225",
    buttonPrimary: "linear-gradient(289.8deg, #149CAD 0%, #4540C1 100%)",
  },
  imageColorOverride: "",
};

export default dark;
