import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ZigLink } from '@zignaly-open/ui';

const AnchorLink = ({ children, ...props }: LinkProps) => (
  <ZigLink {...props} component={Link}>
    {children as JSX.Element}
  </ZigLink>
);
export default AnchorLink;
