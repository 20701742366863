import React, { ReactElement } from "react";
import { Layout } from "./styles";
import ZigTypography from "../ZigTypography";

const ZigStub: React.FC<{
  id?: string;
  title: string | ReactElement;
  description: string | ReactElement;
}> = ({ id, title, description }) => {
  return (
    <Layout id={id}>
      <ZigTypography variant={"h1"}>{title}</ZigTypography>
      <ZigTypography variant={"body1"}>{description}</ZigTypography>
    </Layout>
  );
};

export default ZigStub;
