import { ShowFnOutput } from 'mui-modal-provider';
import { ComponentType, useCallback } from 'react';
import { useCurrentUser } from '../../apis/user/use';
import { generatePath, Params, useNavigate } from 'react-router-dom';
import { UseZModalOptions } from './types';
import { tzTrack } from '../../util/analytics';
import { useZModal as useZModalBase } from '@zignaly-open/ui';

export function useZModal(options?: UseZModalOptions) {
  const { customClose, ...modalOptions } = options || {};
  const result = useZModalBase(modalOptions);
  const { userId } = useCurrentUser();
  const ourShowModal = useCallback(
    (
      Component: ComponentType & { trackId?: string },
      props?: Record<string, unknown>,
      opts?: Record<string, unknown>,
    ) => {
      const trackId = Component.trackId?.toLocaleLowerCase();
      trackId && tzTrack({ hash: trackId, userId });
      const { ...modalProps } = props || {};
      const modal: ShowFnOutput<void> = result.originalShowModal(
        Component,
        {
          ...modalProps,
          close: () => {
            trackId && tzTrack({ userId });
            customClose ? customClose(modal) : modal.destroy();
          },
        },
        opts,
      );
      return modal;
    },
    [result.originalShowModal, userId],
  );

  return {
    ...result,
    showModal: ourShowModal,
  };
}

export function useZRouteModal(
  route: string,
): (params?: Params<string>) => void {
  const navigate = useNavigate();
  return useCallback(
    (params) => navigate(generatePath(route, params || {})),
    [],
  );
}

export {
  useZPrompt,
  useZConfirm,
  useZAlert,
  useZTypeWordConfirm,
} from '@zignaly-open/ui';
