import { Features } from './type';

const defaultFeatureState: Record<Features, boolean> = {
  [Features.ServiceWorker]: true,
  [Features.Rewards]: true,
  [Features.Referrals]: true,
  [Features.AllowBuyCrypto]: true,
  [Features.CreateService]: true,
  [Features.LoginOnlyAccess]: false,
  [Features.NoIndex]: false,
  [Features.EnableTracking]: import.meta.env.PROD,
  [Features.EmailVerification]: true,
  [Features.NewSignup]: true,
  [Features.Subscriptions]: false,
  [Features.MinInvestment]: false,
  [Features.Kyc]: false,
  [Features.ZScore]: true,
  [Features.UsdtBscOnly]: true,
};

export default defaultFeatureState;
