import React from 'react';
import {
  PageContainer,
  ZigButton,
  ZignalyLogotype,
  ZigTypography,
} from '@zignaly-open/ui';
import { useTitle } from 'util/title';
import { Trans, useTranslation } from 'react-i18next';
import { useMaybeSaveMissedDestinationUrl } from '../../util/navigation';
import LanguagesSelector from '../../components/Navigation/LanguagesSelector';
import { Box } from '@mui/material';
import { ROUTE_SIGNUP, ROUTE_LOGIN } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { isOldBadSafari } from '../../util/browser';

const MobileLanding: React.FC = () => {
  useMaybeSaveMissedDestinationUrl();
  const { t } = useTranslation('sign-up');
  const navigate = useNavigate();
  useTitle(t('pages:mobile-landing'));
  return (
    <PageContainer
      style={{
        margin: 0,
        padding: 0,
        ...(isOldBadSafari
          ? {
              paddingTop: '100px',
            }
          : {
              marginTop: '32px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
            }),
      }}
    >
      <Box
        sx={{
          flex: 3,
          alignContent: 'center',
          textAlign: 'center',
          svg: {
            width: '90vw',
            height: '22vw',
            maxHeight: '75px',
            maxWidth: '300px',
            my: 3,
          },
        }}
      >
        <ZignalyLogotype id={'signup__logo'} />

        <Box
          sx={{
            px: 3,
          }}
        >
          <ZigTypography
            variant={'body1'}
            sx={{ fontSize: '20px', lineHeight: 1.6 }}
          >
            <Trans i18nKey={'sign-up:description'} t={t}>
              {/*Didn't use thr react-dom-router-link because it messed up thr bg*/}
              <ZigTypography
                fontWeight={600}
                sx={{
                  fontSize: '20px',
                  lineHeight: 1.6,
                  backgroundImage:
                    'linear-gradient(90deg, #3F3BB1, #138EA0) !important',
                  backgroundClip: 'text',
                  color: 'transparent',
                }}
              />
            </Trans>
          </ZigTypography>
        </Box>
      </Box>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          ...(isOldBadSafari
            ? {
                justifyContent: 'center',
                mt: 3,
              }
            : {}),
        }}
      >
        <ZigButton
          variant={'contained'}
          size={'xlarge'}
          onClick={() => {
            navigate(ROUTE_SIGNUP);
          }}
        >
          {t('pages:signup')}
        </ZigButton>
        <ZigButton
          variant={'outlined'}
          size={'xlarge'}
          onClick={() => {
            navigate(ROUTE_LOGIN);
          }}
        >
          {t('pages:login')}
        </ZigButton>
      </Box>

      <Box sx={{ p: 4, textAlign: 'center' }}>
        <LanguagesSelector />
      </Box>
    </PageContainer>
  );
};

export default MobileLanding;
