import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Action } from './styles';
import { LoginValidation } from './validations';
import { useAuthenticate } from '../../../../apis/user/use';
import { useNavigate } from 'react-router-dom';
import { ROUTE_FORGOT_PASSWORD } from '../../../../routes';
import {
  ZigTypography,
  ZigInput,
  ZigButton,
  ZigLink,
  useZAlert,
} from '@zignaly-open/ui';
import { Box } from '@mui/material';
import { LoginPayload, UserAccessLevel } from '../../../../apis/user/types';
import { isFeatureOn } from '../../../../whitelabel';
import { Features } from '../../../../whitelabel/type';

const LoginForm = ({ onClickSignUp }: { onClickSignUp?: () => void }) => {
  const { t } = useTranslation(['auth', 'error']);
  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm<LoginPayload>({
    mode: 'all',
    defaultValues: {
      email:
        (import.meta.env.DEV && import.meta.env.VITE_TESTING_DEFAULT_EMAIL) ||
        '',
      password:
        (import.meta.env.DEV &&
          import.meta.env.VITE_TESTING_DEFAULT_PASSWORD) ||
        '',
    },
    resolver: yupResolver(LoginValidation),
  });
  const [{ loading: loggingIn }, authenticate] = useAuthenticate();
  const navigate = useNavigate();
  const showAlert = useZAlert();

  const submit = (data: LoginPayload) => {
    authenticate(data).catch((e) => {
      console.error(e);
      if (
        e.data.error.code === 1091 &&
        e.data.error.details.userLevel === UserAccessLevel.Banned
      ) {
        showAlert({
          title: t('access.banned.title', { ns: 'error' }),
          okLabel: t('access.banned.action', { ns: 'error' }),
          description: t('access.banned.description', { ns: 'error' }),
        });
      } else {
        setError('email', { type: 'server', message: e.message });
        setError('password', { type: 'server', message: e.message });
      }
    });
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 436 }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Controller
          name='email'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ZigInput
              id={'login__username'}
              label={t('login-form.inputText.email.label') + ':'}
              placeholder={t('login-form.inputText.email.label')}
              disabled={loggingIn}
              error={t(errors.email?.message)}
              {...field}
            />
          )}
        />

        <Controller
          name='password'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ZigInput
              id={'login__password'}
              labelAction={{
                tabIndex: -1,
                text: t('login-form.inputText.password.labelForgot'),
                onClick: () =>
                  navigate(ROUTE_FORGOT_PASSWORD, {
                    state: { email: watch('email') },
                  }),
                id: 'login__forgot-password',
              }}
              label={t('login-form.inputText.password.label') + ':'}
              placeholder={t('login-form.inputText.password.label')}
              disabled={loggingIn}
              error={t(errors.password?.message)}
              type={'password'}
              sensitive
              {...field}
            />
          )}
        />

        <Action>
          <ZigButton
            type={'submit'}
            variant={'contained'}
            id={'login__submit'}
            size={'xlarge'}
            loading={loggingIn}
          >
            {t('login-form.submit')}
          </ZigButton>

          {/*
          Why does the "Login only" config control if signup is shown (but technically available)?
          Nein, you do not ask zis kind ov qvestionz, vee are just folloving ze orderz
          */}
          {!isFeatureOn(Features.LoginOnlyAccess) && onClickSignUp && (
            <ZigTypography
              variant={'body2'}
              align={'center'}
              id={'login__signup-text'}
            >
              <Trans i18nKey={'login-form.link.signup'} t={t}>
                <ZigLink id={'login__signup'} onClick={onClickSignUp} />
              </Trans>
            </ZigTypography>
          )}
        </Action>
      </Form>
    </Box>
  );
};

export default LoginForm;
