import { useEffect } from 'react';
import i18n from 'i18next';
import { isZignaly } from 'whitelabel';

/**
 * Use this to preload ns if you know some user interaction may trigger the loading of it
 * @param name
 */
export function usePrefetchTranslation(name: string | string[]) {
  useEffect(() => {
    i18n.loadNamespaces(name);
  }, [i18n.language]);
}

type LinksOverridesKeys = 'brokerUrl';

export const loadLinkOverride = (
  key: LinksOverridesKeys,
  zignalyValue: string,
  defaultShouldUseZignalyValue = false,
) => {
  const hasOverride = i18n.exists(`links-overrides:${key}`, {
    lng: 'en',
  });

  if (hasOverride) {
    return i18n.t(`links-overrides:${key}`, {
      lng: 'en',
    });
  }

  if (zignalyValue && (isZignaly || defaultShouldUseZignalyValue)) {
    return zignalyValue;
  }

  return '';
};
