import React from "react";
import { ZigArrowBottomIconStyled, ZigMenuItemSubHeader } from "./styles";
import { Check } from "@mui/icons-material";
import { Box, SxProps, useMediaQuery, useTheme } from "@mui/material";
import ZigTypography from "../../display/ZigTypography";
import { ZigSubHeaderRoute } from "./types";
import { Link, matchPath, useInRouterContext } from "react-router-dom";

export const SubHeaderMenuButton = ({
  route,
  sx,
  open,
}: {
  route: ZigSubHeaderRoute;
  open: boolean;
  sx?: SxProps;
}) => {
  const currentPath = window.location.pathname; // storybook doesn't have useLocation context
  const { label, routes, secondaryTitle } = route;
  const active = routes?.some(
    (x) => x.active || (x.href && matchPath({ path: x.href }, currentPath)),
  );
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      justifyContent={"space-between"}
      alignItems={"center"}
      gap="8px"
      flex={1}
      sx={sx}
    >
      <Box
        display="flex"
        flexDirection={"column"}
        {...(!secondaryTitle && {
          textAlign: "center",
          width: "100%",
        })}
      >
        {secondaryTitle && (
          <ZigTypography color="neutral400" variant={"caption"} component="p">
            {secondaryTitle}
          </ZigTypography>
        )}
        <ZigTypography
          sx={{
            fontWeight: 400,
            color: !secondaryTitle ? (active ? "highlighted" : "neutral300") : "neutral100",
            whiteSpace: sm ? "normal" : "nowrap",
          }}
          fontWeight={400}
          variant={"h3"}
          component="span"
        >
          {label}
        </ZigTypography>
      </Box>
      <ZigArrowBottomIconStyled rotated={open} />
    </Box>
  );
};

export const SubHeaderOption = ({
  route,
  isSubOption,
  sx,
  wrapMenuItem,
}: {
  route: ZigSubHeaderRoute;
  isSubOption?: boolean;
  wrapMenuItem?: boolean;
  sx?: SxProps;
}) => {
  const {
    label,
    sideElement,
    element,
    routes,
    href,
    separator,
    sx: optionSx,
    onClick,
    ...optionProps
  } = route;
  const routerContext = useInRouterContext();
  const currentPath = window.location.pathname;
  const active = route.active || (route.href && matchPath(route.href, currentPath));
  const propsToAdd = {
    sx: { ...sx, ...optionSx },
    color: active ? "highlighted" : wrapMenuItem ? "neutral300" : "inherit",
    ...(href &&
      (routerContext
        ? {
            component: Link,
            to: href,
          }
        : { component: "a" as React.ElementType, href })),
    ...optionProps,
  };

  const renderOption = () => (
    <Box
      display="flex"
      alignItems="center"
      gap={"5px"}
      justifyContent={isSubOption ? "space-between" : "center"}
      flex={1}
      {...(!wrapMenuItem && propsToAdd)}
    >
      <ZigTypography
        sx={{
          fontWeight: 400,
          color: "inherit",
        }}
        variant={"h3"}
        display="flex"
        flex={1}
        justifyContent={isSubOption ? "flex-start" : "center"}
        alignItems={"center"}
        gap={"5px"}
      >
        {label}
        {sideElement}
      </ZigTypography>
      {isSubOption && !!active && <Check />}
    </Box>
  );

  if (!wrapMenuItem) return renderOption();

  return (
    <ZigMenuItemSubHeader
      {...propsToAdd}
      onClick={onClick}
      sx={{ color: propsToAdd.color, ...propsToAdd.sx }}
      isSubOption={isSubOption}
    >
      {renderOption()}
    </ZigMenuItemSubHeader>
  );
};
