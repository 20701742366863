import React, { useImperativeHandle } from "react";
import { ZigMenuProps, ZigMenuHandle, ZigMenuOption } from "./types";
import { Popper, ClickAwayListener, Grow, Box, PopperPlacementType } from "@mui/material";
import { MenuContent } from "./atoms";

const getPlacementStyles = (placement: PopperPlacementType, matchAnchorWidth: boolean) => {
  switch (placement) {
    case "bottom-start":
      return {
        transformOrigin: "left top",
        borderRadius: `0 ${matchAnchorWidth ? 0 : 10}px 4px 4px`,
      };
    case "bottom-end":
      return {
        transformOrigin: "right top",
        borderRadius: `${matchAnchorWidth ? 0 : 10}px 0 4px 4px`,
      };
    default:
      return {
        transformOrigin: "auto",
        borderRadius: "4px",
      };
  }
};

const ZigMenu: (props: ZigMenuProps, innerRef: React.Ref<ZigMenuHandle>) => JSX.Element = (
  {
    id = "",
    component,
    options,
    placement = "bottom-end",
    matchAnchorWidth = false,
    sx,
    menuSx,
    disabled,
  }: ZigMenuProps,
  innerRef: React.Ref<ZigMenuHandle>,
) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [expandedOption, setExpandedOption] = React.useState<null | ZigMenuOption>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    if (expandedOption) setExpandedOption(null);
  };

  const handleClose = (event?: Event | React.SyntheticEvent) => {
    if (event?.target && anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
    setExpandedOption(null);
  };

  const placementStyles = getPlacementStyles(placement, matchAnchorWidth);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useImperativeHandle(
    innerRef,
    () => ({
      closeDropDown: () => {
        handleClose();
      },
      open,
    }),
    [anchorRef.current, open],
  );

  const onClick = (cb?: () => void) => (e: React.MouseEvent<HTMLElement>) => {
    handleClose(e);
    cb?.();
  };

  return (
    <>
      <Box
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        ref={anchorRef}
        role={"button"}
        onClick={!disabled ? handleToggle : undefined}
        id={id && `${id}-container`}
        sx={{ cursor: "pointer", ...sx }}
      >
        {component({ open })}
      </Box>
      {options && open && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={"presentation"}
          placement={placement}
          transition
          disablePortal={false}
          sx={{
            minWidth: matchAnchorWidth ? anchorRef.current?.offsetWidth : "245px",
            zIndex: 1300,
            "> div": {
              boxShadow: "0 4px 6px -2px #00000061",
              borderRadius: placementStyles.borderRadius,
              overflow: "hidden",
              backgroundColor: "neutral800",
            },
            "*": {
              whiteSpace: "normal",
            },
            // Workaround to prevent the menu from being visible when a click triggers a suspense route,
            // leaving the menu in an undesirable style state.
            transform: "scale(0)",
            ...menuSx,
          }}
          modifiers={[
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: false,
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placementStyles.transformOrigin,
              }}
              timeout={open ? 180 : 0}
            >
              <div>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <MenuContent
                      id={id}
                      options={options}
                      setExpandedOption={setExpandedOption}
                      expandedOption={expandedOption}
                      onClick={onClick}
                      setOpen={setOpen}
                    />
                  </div>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default React.forwardRef(ZigMenu);
export * from "./types";
