import { Box, Theme, css, styled } from "@mui/material";
import { lighten } from "@mui/material/styles";
import { ZigMenuItem } from "@ui/components/display/ZigMenu/styles";
import { ZigArrowBottomIcon } from "@ui/icons";

const secondaryBackground = ({ theme }: { theme: Theme }) => `
  background: ${lighten(theme.palette.neutral900, (0x0f - 0x05) / 0xff)};
`;

export const Layout = styled("div")`
  position: sticky;
  top: var(--header-height, 52px);
  width: 100%;
  right: 0;
  left: 0;
  ${secondaryBackground};
  z-index: 50;

  .MuiMenuItem-root {
    transition: background-color 0.2s;
    &:not([disabled]) {
      &:hover {
        color: ${({ theme }) => theme.palette.neutral100};
      }
    }
  }
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  & > * {
    flex: 1;
    border-right: 1px dotted ${({ theme }) => theme.palette.neutral600};
    padding: 6px 32px;
  }
  &:first-of-type {
    border-left: 1px dotted ${({ theme }) => theme.palette.neutral600};
  }
`;

export const ZigMenuItemSubHeader = styled(ZigMenuItem, {
  shouldForwardProp: (p) => p !== "isSubOption",
})<{ isSubOption?: boolean }>`
  ${({ isSubOption, theme }) => css`
    ${!isSubOption &&
    css`
      border-right: 1px dotted ${theme.palette.neutral600};
    `};
  `}
`;

export const ZigArrowBottomIconStyled = styled(ZigArrowBottomIcon, {
  shouldForwardProp: (p) => p !== "rotated",
})<{ rotated: boolean }>`
  transition: all 0.15s linear;
  font-size: 22px;
  min-width: 1em;
  fill: ${({ theme }) => theme.palette.neutral300};
  transform: rotate(${(props) => (props.rotated ? "180deg" : "0deg")});
`;
