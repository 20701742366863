import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogProps } from "@mui/material/Dialog";

import { Box } from "@mui/system";
import ZModal from "..";
import { ConfirmModalProps } from "./ConfirmModal";
import { useMediaQuery, useTheme } from "@mui/material";
import ZigTypography from "../../ZigTypography";
import ZigInput from "../../../inputs/ZigInput";
import ZigButton from "../../../inputs/ZigButton";

export type TypeTextConfirmModalProps = {
  safeWord?: string;
  cancelButton?: boolean;
} & ConfirmModalProps;

function TypeTextConfirmModal({
  close,
  title,
  description,
  yesLabel,
  yesAction,
  safeWord = "Fluggaenkoecchicebolsen",
  yesButtonProps,
  noButtonProps,
  noLabel,
  noAction,
  cancelButton = true,
  prefixId,
  ...props
}: {
  close: () => void;
} & TypeTextConfirmModalProps &
  DialogProps): React.ReactElement {
  const { t } = useTranslation("zignaly-ui", { keyPrefix: "ZModal" });
  const [confirmWord, setConfirmWord] = useState("");
  const [loading, setLoading] = useState(false);
  const typedIncorrectly = confirmWord?.toLocaleLowerCase() !== safeWord.toLocaleLowerCase();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <ZModal wide {...props} close={close} title={title} id={prefixId} mobileFullScreen>
      {!!description && typeof description === "string" && (
        <ZigTypography
          sx={{ mb: 1 }}
          textAlign={"center"}
          id={prefixId && `${prefixId}__description`}
        >
          {description}
        </ZigTypography>
      )}

      {!!description && typeof description !== "string" && description}

      <ZigInput
        sx={{
          ".MuiInputLabel-root": {
            whiteSpace: "normal",
          },
        }}
        disabled={loading}
        id={prefixId && `${prefixId}__type-to-confirm`}
        label={t("type-to-confirm", { word: safeWord.toUpperCase() })}
        onChange={(e) => {
          setConfirmWord(e.target.value);
        }}
        value={confirmWord}
        placeholder={safeWord.toUpperCase()}
        fullWidth
      />

      <Box
        sx={{
          mt: 4,
          ml: "auto",
          mr: "auto",
        }}
        display={"flex"}
        flexDirection={sm ? "column" : "row"}
        gap={2}
      >
        <ZigButton
          id={prefixId && `${prefixId}__confirm`}
          disabled={typedIncorrectly || loading}
          tooltip={
            typedIncorrectly ? t("type-to-confirm", { word: safeWord.toUpperCase() }) : undefined
          }
          type="submit"
          size={"large"}
          onClick={async () => {
            setLoading(true);
            await yesAction();
            close();
          }}
          {...(yesButtonProps || {})}
        >
          {yesLabel || t("confirm")}
        </ZigButton>

        {cancelButton && (
          <ZigButton
            variant={"outlined"}
            type="submit"
            size={"large"}
            onClick={() => {
              noAction?.();
              close();
            }}
            id={prefixId && `${prefixId}__cancel`}
            {...(noButtonProps || {})}
          >
            {noLabel || t("cancel")}
          </ZigButton>
        )}
      </Box>
    </ZModal>
  );
}

export default TypeTextConfirmModal;
