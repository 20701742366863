import { useAsync } from 'react-use';

function useCurrency() {
  const { value: currency } = useAsync(async () => {
    try {
      const response = await fetch(
        `https://api.ipgeolocation.io/ipgeo?apiKey=${
          import.meta.env.VITE_IPGEOLOCATION_API_KEY
        }`,
      );
      const data = await response.json();
      return data.currency.code;
    } catch (e) {
      return 'USD';
    }
  }, []);

  return currency;
}

export default useCurrency;
