import * as yup from 'yup';
import i18n from 'util/i18n/i18next';

const specialRegex = /[`!@#$%^&*()_+\-=[\]{};':"|,.<>/?~\\]/;
const PASSWORD_LENGTH = 8;

export const passwordYup = yup
  .string()
  .required('error:error.required')
  .min(PASSWORD_LENGTH, () =>
    i18n.t('error:error.password-requirements', {
      length: PASSWORD_LENGTH,
    }),
  )
  // At least one special character
  .matches(specialRegex, () =>
    i18n.t('error:error.password-requirements', {
      length: PASSWORD_LENGTH,
    }),
  )
  // At least one uppercase and one lowercase letter
  .test(
    'uppercase-lowercase',
    () =>
      i18n.t('error:error.password-requirements', { length: PASSWORD_LENGTH }),
    (value) => {
      return /[a-z]/.test(value) && /[A-Z]/.test(value);
    },
  )
  // At least one number
  .matches(/\d/, () =>
    i18n.t('error:error.password-requirements', {
      length: PASSWORD_LENGTH,
    }),
  );

export const SignupValidation = yup
  .object({
    email: yup
      .string()
      .required('error:error.required')
      .email('error:error.email-invalid'),
    password: passwordYup,
  })
  .required();
