import styled from "@emotion/styled";
import { Palette } from "@mui/material";

export const Caption = styled("div")<{ variant: string }>`
  margin: 0 8px;
  color: ${({ variant, theme: { palette } }) =>
    variant === "success" ? palette.green : variant === "error" ? palette.red : palette.neutral200};
`;

export const IconContainer = styled("div")<{ size: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    width: ${({ size }) => (size === "large" ? "26px" : "24px")};
    height: ${({ size }) => (size === "large" ? "26px" : "24px")};
  }
`;

export const ToastContainer = styled("div")<{ variant: string; size: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 5px;
  position: absolute;
  padding: ${({ size }) => (size === "large" ? "11px 24px" : "4px 18px")};
  height: ${({ size }) => (size === "large" ? "48px" : "32px")};
  border: 1px solid ${({ variant, theme: { palette } }) => getVariantColor(variant, palette)};
  background: ${({ variant, theme: { palette } }) => getVariantColor(variant, palette)};
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 0;
    height: unset;
    min-height: ${({ size }) => (size === "large" ? "48px" : "32px")};
  }
`;

function getVariantColor(variant: string, palette: Palette) {
  switch (variant) {
    case "success":
      return "#122431";
    case "error":
      return "#231630";
    default:
      return palette.neutral600;
  }
}
