import React from 'react';
import { Grid } from '@mui/material';

export const BinanceBrokerImages = () => {
  return (
    <Grid
      item
      container
      alignItems={'center'}
      justifyContent={'center'}
      gap={'8px'}
      sx={{ opacity: 0.7 }}
    >
      <Grid item>
        <img src={'/images/signup/secure-ssh.svg'} alt='ssl' id={'ssl_icon'} />
      </Grid>
      <Grid item>
        <img
          src={'/images/signup/funds-protected.svg'}
          alt='binance'
          id={'safu_icon'}
        />
      </Grid>
    </Grid>
  );
};

export default BinanceBrokerImages;
