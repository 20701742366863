import { styled, css, Theme } from "@mui/system";
import { lighten } from "@mui/material/styles";
import { MenuItem, Divider } from "@mui/material";
import { ZigArrowBottomIcon } from "@ui/icons";

const hoverBackground = (theme: Theme) => lighten(theme.palette.neutral800, 0xa / 0xff);

export const ZigMenuContainer = styled("div")`
  min-width: 245px;
`;

export const ZigMenuItem = styled(MenuItem, {
  shouldForwardProp: (p) => !["active", "preserveStyles", "separator"].includes(p as string),
})<{
  active?: boolean;
  preserveStyles?: boolean;
  component?: React.ElementType | string;
  to?: string;
}>`
  ${({ preserveStyles = true, theme, active }) => css`
    color: ${active && preserveStyles ? theme.palette.highlighted : theme.palette.neutral200};
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    padding: 6px 32px;
    letter-spacing: 0.55px;
    text-decoration: none;
    gap: 12px;

    &:not([disabled]) {
      &:hover,
      &:focus-visible {
        background: ${hoverBackground(theme)};
        color: ${theme.palette.neutral000};
      }
    }

    ${!preserveStyles &&
    css`
      && {
        gap: 0;
        line-height: normal;

        &:hover {
          background-color: inherit;
          cursor: auto;
        }
      }
    `}
  `}
`;

export const StyledDivider = styled(Divider)`
  border: 1px dotted ${({ theme }) => theme.palette.neutral600};
  margin: 6px 32px;
`;

export const ChildContainer = styled("div", {
  shouldForwardProp: (p) => p !== "active",
})<{ active: boolean }>`
  ${({ active, theme }) =>
    active &&
    css`
      background: ${theme.palette.neutral750};
      /* When the menu is expanded, replace bottom margin with padding to fit the background until the bottom */
      margin-bottom: 0 !important;
      padding-bottom: 12px;
    `}
`;

export const SpaceTaker = styled("span")`
  display: flex;
  flex: 1;
`;

export const ArrowBottomIconStyled = styled(ZigArrowBottomIcon, {
  shouldForwardProp: (p) => p !== "rotated",
})<{ rotated: boolean }>`
  transition: 0.15s linear;
  transform: rotate(${(props) => (props.rotated ? "180deg" : "0deg")});
  fill: ${({ theme }) => theme.palette.neutral300};
  font-size: 20px;
  margin-right: -4px;
`;
