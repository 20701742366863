import { useMatch } from 'react-router-dom';
import { ROUTE_MOBILE_LANDING, ROUTE_SIGNUP } from '../../../routes';
import { isFeatureOn } from '../../../whitelabel';
import { Features } from '../../../whitelabel/type';

export function useShouldShowHeader(): boolean {
  const isNewSignup = useMatch(ROUTE_SIGNUP) && isFeatureOn(Features.NewSignup);
  const isCapacitorLanding = useMatch(ROUTE_MOBILE_LANDING);
  return !isNewSignup && !isCapacitorLanding;
}
