import React from "react";
import { useTranslation } from "react-i18next";
import { DialogProps } from "@mui/material/Dialog";
import { Box } from "@mui/system";
import ZModal from "../index";
import ZigTypography from "../../ZigTypography";
import ZigButton, { ZigButtonProps } from "../../../inputs/ZigButton";

export type AlertModalProps = {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  okLabel?: string | JSX.Element;
  okButtonProps?: Partial<ZigButtonProps>;
  okAction?: () => void;
};

function AlertModal({
  close,
  title,
  description,
  okLabel,
  okButtonProps,
  okAction,
  ...props
}: {
  close: () => void;
} & AlertModalProps &
  DialogProps): React.ReactElement {
  const { t } = useTranslation("zignaly-ui", { keyPrefix: "ZModal" });

  return (
    <ZModal wide {...props} close={close} title={title} mobileFullScreen>
      <div>
        {!!description && (
          <ZigTypography sx={{ textAlign: "center" }} component={"p"} id="alert__description">
            {description}
          </ZigTypography>
        )}

        <Box sx={{ mt: "35px", textAlign: "center" }}>
          <ZigButton
            id="alert__button"
            sx={{ mr: 1 }}
            variant={"contained"}
            type="submit"
            size={"large"}
            {...(okButtonProps || {})}
            onClick={() => {
              okAction?.();
              close();
            }}
          >
            {okLabel || t("confirm")}
          </ZigButton>
        </Box>
      </div>
    </ZModal>
  );
}

export default AlertModal;
