const allCoins = ['USDT', 'ETH', 'BNB', 'BTC'];

export function getAllowedDeposits(usdtBscOnly: boolean) {
  return {
    spot: !usdtBscOnly ? allCoins : ['USDT'],
    futures: ['USDT'],
  };
}

export function getAllowedNetworks(usdtBscOnly: boolean) {
  return usdtBscOnly ? { spot: ['BSC'] } : null;
}

export function getAllowedSwapsTo(usdtBscOnly: boolean) {
  return usdtBscOnly ? ['USDT'] : allCoins;
}

export function getRestrictedSwapFrom(usdtBscOnly: boolean) {
  return usdtBscOnly ? ['USDT'] : [];
}

export function getServiceCoins(usdtBscOnly: boolean) {
  return usdtBscOnly ? ['USDT'] : allCoins;
}
