import { styled } from "@mui/system";

export const Layout = styled("div", {
  shouldForwardProp: (prop) => prop !== "error" && prop !== "loading",
})<{ error?: string; loading?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .vi__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 64px;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      height: 54px;

      .vi__character {
        height: 100%;
        width: 45px;
        max-width: calc((100vw - 120px) / 6);
      }
    }

    ${(props) => props.error && `margin-bottom: 10px;`}

    .vi__character--selected {
      outline: none;

      &::after {
        content: "";
        width: 1px;
        height: 25px;
        background-color: ${({ theme }) => theme.palette.neutral100};
        animation: blink 1s infinite step-end;
      }
    }

    input {
      // 1Password autofill fix
      &[data-com-onepassword-filled] {
        background-clip: text;
        font-size: 0;
      }
    }

    .vi__character {
      width: 64px;
      background-color: ${({ theme }) => theme.palette.backgrounds.neutralBg};
      border-radius: 8px;
      border: 1px solid
        ${(props) => (props.error ? props.theme.palette.red : props.theme.palette.neutral600)};
      color: ${(props) => props.theme.palette.neutral100};
      font-family: ${
        // @ts-ignore
        (props) => props.theme.typography.fontFamily
      };
      font-size: 26px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.vi__character--filled {
        background-image: linear-gradient(
            to bottom,
            ${(props) => props.theme.palette.backgrounds.neutralBg},
            ${(props) => props.theme.palette.backgrounds.neutralBg}
          ),
          ${(props) => props.theme.palette.backgrounds.buttonPrimary};
        background-origin: border-box, border-box;
        background-clip: padding-box, border-box;
        border: 1px solid transparent;
      }
    }
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
`;
