import React, { useCallback, useEffect, useRef } from 'react';
import { KYC_CHECK_INTERVAL, KYC_PASSED_CHECK_INTERVAL } from './constants';
import { useToast } from '../../../util/hooks/useToast';
import { useTranslation } from 'react-i18next';
import { useCurrentUser, useIsAuthenticated } from '../../../apis/user/use';
import {
  useLazyKycStatusesQuery,
  useLazyUserQuery,
} from '../../../apis/user/api';
import { isFeatureOn } from 'whitelabel';
import { Features } from 'whitelabel/type';
import { useDispatch } from 'react-redux';
import { setUser } from 'apis/user/store';
import { useTraderServices } from 'apis/service/use';
import { KycStatus } from 'apis/user/types';
import { useZAlert } from 'components/ZModal/use';
import { differenceInDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ROUTE_KYC } from 'routes';
import { junkyard } from 'util/junkyard';

const UserKycChecker: React.FC = () => {
  const toast = useToast();
  const { data: traderServices, isLoading: isLoadingServices } =
    useTraderServices();
  const { exchanges, KYCMonitoring: shouldMonitorKyc } = useCurrentUser();
  const exchangeActivated = exchanges?.some((e) => e.activated);
  const isAuthenticated = useIsAuthenticated();
  const { t, ready } = useTranslation(
    [...(isFeatureOn(Features.Kyc) ? ['kyc'] : []), 'common'],
    // Would be required in all pages while not being a priority
    { useSuspense: false },
  );

  const [loadUser] = useLazyUserQuery();
  const [loadKyc] = useLazyKycStatusesQuery();
  const shouldCheck =
    isAuthenticated &&
    isFeatureOn(Features.Kyc) &&
    !isLoadingServices &&
    (exchangeActivated || shouldMonitorKyc);

  const dispatch = useDispatch();

  const getStatuses = useCallback(async () => {
    try {
      const data = await loadKyc().unwrap();
      const { statuses, kycMonitoring } = data;

      return {
        statusesSerialized: statuses.map((x) => x.status || '').join(''),
        statuses,
        shouldCheck: kycMonitoring,
        kycLvl1: statuses.find((s) => s.category === 'KYC' && s.level === 1),
      };
    } catch (e) {
      // Silently fail due to unwrap returning undefined on session expired
    }
  }, []);
  const oldStatuses = useRef<Awaited<ReturnType<typeof getStatuses>>>();

  const showAlert = useZAlert();
  const navigate = useNavigate();

  useEffect(() => {
    // Wait for translations to be ready.
    // Kyc i18n is not blocking the suspense, so we need to wait for it.
    if (!ready) return;

    let timeoutId: NodeJS.Timeout = null;
    // Ignore double renders in strict mode
    let ignore = false;

    (async () => {
      const pollKyc = (approved: boolean) => {
        const timeout = approved
          ? KYC_PASSED_CHECK_INTERVAL
          : KYC_CHECK_INTERVAL;
        timeoutId = setTimeout(async () => {
          if (!isAuthenticated) return;
          const newStatus = await getStatuses();
          if (!newStatus) return;

          if (
            newStatus.statusesSerialized !==
            oldStatuses.current.statusesSerialized
          ) {
            toast.info(
              t(
                oldStatuses.current.kycLvl1?.status !== KycStatus.EXPIRED &&
                  newStatus.kycLvl1?.status === KycStatus.EXPIRED
                  ? 'common:kyc-expired'
                  : 'common:kyc-updated',
              ),
            );
            oldStatuses.current = newStatus;
            const userData = await loadUser().unwrap();
            dispatch(setUser(userData));
          }

          if (approved || newStatus.shouldCheck) {
            pollKyc(approved);
          }
        }, timeout);
      };

      if (ignore || !shouldCheck) return;
      const newStatus = await getStatuses();
      if (!newStatus) return;
      const { kycLvl1 } = newStatus;
      oldStatuses.current = newStatus;

      const kycPushedDate = junkyard.get('kycPushed');
      const needPush = [
        KycStatus.NOT_STARTED,
        KycStatus.INIT,
        KycStatus.REJECTED_RETRY,
        KycStatus.EXPIRED,
      ].includes(kycLvl1.status);
      const approved = kycLvl1.status === KycStatus.APPROVED;

      if (
        needPush &&
        (!kycPushedDate ||
          differenceInDays(new Date(), new Date(kycPushedDate)) >= 1) &&
        traderServices.length
      ) {
        junkyard.set('kycPushed', new Date().toISOString());
        showAlert({
          title: t('service-push.title'),
          okLabel: t('service-push.ok'),
          description: t('service-push.description'),
          okAction: () => {
            navigate(ROUTE_KYC);
          },
        });
      }

      pollKyc(approved);
    })();

    return () => {
      ignore = true;
      clearTimeout(timeoutId);
    };
  }, [shouldCheck, ready]);

  return null;
};

export default UserKycChecker;
