import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const DrawerMenuItem: React.FC<{
  closeDrawer: () => void;
  id: string;
  path: string;
  label: string;
}> = ({ closeDrawer, id, path, label }) => (
  <ListItem disablePadding onClick={closeDrawer} sx={{ pl: 4 }}>
    <ListItemButton id={id} to={path} component={Link}>
      <ListItemText primary={label} />
    </ListItemButton>
  </ListItem>
);
