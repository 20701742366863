import React from "react";
import { GlobalStyles, css } from "@mui/material";
import AvenirNextFont from "../assets/fonts/AvenirNext/AvenirNext-Regular.ttf";
import AvenirNextMediumFont from "../assets/fonts/AvenirNext/AvenirNext-Medium.ttf";
import AvenirNextDemiFont from "../assets/fonts/AvenirNext/AvenirNext-Demi.ttf";
import AvenirNextBoldFont from "../assets/fonts/AvenirNext/AvenirNext-Bold.ttf";

const fontFaces = css`
  @font-face {
    font-family: "Avenir Next";
    src: url(${AvenirNextFont});
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Avenir Next";
    src: url(${AvenirNextMediumFont});
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Avenir Next";
    src: url(${AvenirNextDemiFont});
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Avenir Next";
    src: url(${AvenirNextBoldFont});
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`;

export const AvenirNext = () => <GlobalStyles styles={fontFaces} />;
